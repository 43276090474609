import api from "../../services/api";
import { SET_REQUEST, SET_REQUEST_COMPANY, SET_REQUEST_ENGINEER, SET_REQUEST_ENGINEERS, SET_REQUEST_MACHINE, SET_REQUEST_REPORT, SET_REQUEST_SERVICEMAN, SET_REQUEST_SERVICEMANS, SET_REQUEST_USER, SET_REQUEST_WORKS, SET_SERVICE_COMPANY } from "./types";
import { toast } from "react-toastify";

export const setRequest = data => {
    return {
        type: SET_REQUEST,
        payload: data
    }
}

export const getRequest = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/work/request/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequest(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestUser = data => {
    return {
        type: SET_REQUEST_USER,
        payload: data
    }
}

export const getRequestUser = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/user/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestUser(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestCompany = data => {
    return {
        type: SET_REQUEST_COMPANY,
        payload: data
    }
}

export const getRequestCompany = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestCompany(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestMachine = data => {
    return {
        type: SET_REQUEST_MACHINE,
        payload: data
    }
}

export const getRequestMachine = (nomer) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/machines/${nomer}/params`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestMachine(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestEngineers = data => {
    return {
        type: SET_REQUEST_ENGINEERS,
        payload: data
    }
}

export const getRequestEngineers = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/12/users?role=7`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestEngineers(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setServicesCompany = data => {
    return {
        type: SET_SERVICE_COMPANY,
        payload: data
    }
}

export const getServicesCompany = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company?type=3`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setServicesCompany(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestServiceMans = data => {
    return {
        type: SET_REQUEST_SERVICEMANS,
        payload: data
    }
}

export const getRequestServiceMans = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/12/users?role=8`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestServiceMans(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const delegateRequest = (request, engineer, filters) => async (dispatch, getState) => {
    try {
        const data = new FormData()

        data.append("engineer_id", engineer)
        if (filters !== "") data.append("filters", filters)

        const response = await api.post(`/api/v1/work/request/${request}/engineer/delegate`, data)

        if (response.status === 201) {
            dispatch(getRequest(request))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const rejectRequest = (request, comment) => async (dispatch, getState) => {
    try {
        const data = new FormData()

        data.append("comment", comment)

        const response = await api.post(`/api/v1/work/request/${request}/rejected`, data)

        if (response.status === 201) {
            dispatch(getRequest(request))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const addRequestFiles = (requestID, files) => async (dispatch, getState) => {
    try {
        const data = new FormData()

        if (files.length > 0) {
            files.forEach((item, i) => {
                data.append(`files[${i}]`, item, item.name)
            })
        }

        const response = await api.post(`/api/v1/work/request/${requestID}/file`, data)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const setRequestEngineer = data => {
    return {
        type: SET_REQUEST_ENGINEER,
        payload: data
    }
}

export const getRequestEngineer = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/user/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestEngineer(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setRequestServiceMan = data => {
    return {
        type: SET_REQUEST_SERVICEMAN,
        payload: data
    }
}

export const getRequestServiceMan = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/user/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestServiceMan(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setServiceManRequest = (request, serviceMan) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/work/request/${request}/engineer/delegate`, { 'service_man_id': serviceMan })

        if (response.status === 200) {
            dispatch(getRequest(request))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const setDelegateService = (request, service) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/work/request/${request}/service/delegate`, { 'company_id': service })

        if (response.status === 200) {
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const setRequestWorks = data => {
    return {
        type: SET_REQUEST_WORKS,
        payload: data
    }
}

export const getRequestWorks = (requestID) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/work/request/${requestID}/task`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestWorks(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const addRequestWorkComment = (requestID, workID, comment, files) => async (dispatch, getState) => {
    try {
        const data = new FormData()

        data.append("comment", comment)
        if (files.length > 0) {
            Object.keys(files).forEach((item, i) => {
                data.append(`files[${i}]`, files[item], files[item].name)
            })
        }

        const response = await api.post(`/api/v1/work/request/${requestID}/task/${workID}/comment`, data)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            dispatch(getRequestWorks(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const copyRequestWork = (requestID, workID, comment, files, type) => async (dispatch, getState) => {
    try {
        const data = new FormData()
        data.append("description", comment)
        data.append("work_type_id", type)
        if (files.length > 0) {
            Object.keys(files).forEach((item, i) => {
                data.append(`file_moove[${i}][id]`, files[item])
            })
        }

        const response = await api.post(`/api/v1/work/request/${requestID}/moove/${workID}`, data)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            dispatch(getRequestWorks(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const culpritRequestWork = (requestID, workID, culprit) => async (dispatch, getState) => {
    try {

        const response = await api.post(`/api/v1/work/request/${requestID}/task/${workID}/culprit`, { culprit_id: culprit })

        if (response.status === 200) {
            dispatch(getRequest(requestID))
            dispatch(getRequestWorks(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const pointRequestWork = (requestID, workID, worker, point) => async (dispatch, getState) => {
    try {

        const response = await api.put(`/api/v1/work/request/${requestID}/task/${workID}/${worker}`, { point: point })

        if (response.status === 200) {
            dispatch(getRequest(requestID))
            dispatch(getRequestWorks(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}


export const addRequestWork = (requestID, subCat, comment, files) => async (dispatch, getState) => {
    try {
        const data = new FormData()

        data.append("work_category_id", subCat)
        data.append("comment", comment)
        if (files.length > 0) {
            files.forEach((item, i) => {
                data.append(`files[${i}]`, item, item.name)
            })
        }

        const response = await api.post(`/api/v1/work/request/${requestID}/task`, data)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            dispatch(getRequestWorks(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const setRequestReport = data => {
    return {
        type: SET_REQUEST_REPORT,
        payload: data
    }
}

export const getRequestReport = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/work/request/${id}/report`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequestReport(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const addRequestReport = (requestID, data) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/request/${requestID}/report`, data)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const successRequestReport = (requestID) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/request/${requestID}/report/success`)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const setRequestAccepted = (requestID) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/request/${requestID}/status/accepted`)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const takeRequest = (requestID) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/request/${requestID}/to_work`)

        if (response.status === 201) {
            dispatch(getRequest(requestID))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}