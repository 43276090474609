import { Grid } from "@mui/material"
import RequestsList from "./RequestsList"
import RequestsParams from "./RequestsParams"

const Service = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <RequestsParams />
                </Grid>
                <Grid item xs={9}>
                    <RequestsList />
                </Grid>
            </Grid>
        </>
    )
}

export default Service