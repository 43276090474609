import {
    Box,
    Button, Checkbox,
    Divider, FormControl, FormControlLabel,
    FormGroup, FormLabel,
    Grid, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ServiceSelectors from "../../../store/Service/selectors";
import * as RequestSelectors from "../../../store/Request/selectors";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import AddWorkComment from "./AddWorkComment";
import CopyToRequest from "./CopyToRequest";
import { culpritRequestWork, pointRequestWork } from '../../../store/Request/actions'
import * as AuthSelector from "../../../store/Auth/selectors";

const Work = ({ data, edit }) => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)
    const categories = useSelector(ServiceSelectors.categories)
    const culprits = useSelector(ServiceSelectors.culprits)
    const user = useSelector(AuthSelector.user)

    const [cat, setCat] = useState("")
    const [subCat, setsSubCat] = useState("")
    const [open, setOpen] = useState(false)
    const [copy, setCopy] = useState(false)

    const findCategory = () => {
        categories.forEach(cat => {
            cat.children.forEach(subCat => {
                if (subCat.id === data.work_category_id) {
                    setsSubCat(subCat.title)
                    setCat(cat.title)
                }
            })
        })
    }

    useEffect(() => {
        if (categories.length !== 0 && cat === "") {
            findCategory()
        }
    }, [categories.length])

    const Files = ({ date }) => {

        const find = data.files.filter(file => file.created_at === date)

        if (!find) return null

        return (
            <>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {find.map(file => (
                        file.file_type === 'image' ?
                            <Box sx={{ width: 50, p: 1 }} key={file.id}>
                                <a target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                    <img style={{ width: '100%' }} src={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} />
                                </a>
                            </Box> : null
                    ))}
                </Box>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {find.map(file => (
                                file.file_type === 'doc' ?
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <a style={{ textDecoration: 'none' }} target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                                <Button variant="contained" startIcon={<DescriptionIcon />}>
                                                    {file.file}
                                                </Button>
                                            </a>
                                        </TableCell>
                                    </TableRow> : null
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const Comment = ({ data }) => {
        return (
            <>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={8}>
                        <Box sx={{ p: 2 }}>
                            <Typography variant={'subtitle1'}>
                                {data.comment}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                                {moment(data.created_at).format("DD.MM.YYYY hh:mm:ss")} — {data.user.name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Files date={data.created_at} />
                    </Grid>
                </Grid>
                <Divider sx={{ mb: 1 }} />
            </>
        )
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCopyClose = () => {
        setCopy(false)
    }

    const handlerCulprit = (id) => {
        dispatch(culpritRequestWork(request.id, data.id, id))
    }

    const checkCulprit = () => {
        if (data.culprit) {
            return data.culprit.culprit_id
        }
        return 0
    }

    const checkWork = (e, worker) => {
        const point = e.target.checked ? 1 : 0
        dispatch(pointRequestWork(request.id, data.id, worker, point))
    }

    const EditBlock = () => {

        return (
            <Box>
                <Box sx={{ mb: 2 }}>
                    <Button onClick={() => setOpen(true)}>Добавить комментарий</Button>
                    {edit ? <Button onClick={() => setCopy(true)}>Создать отдельную заявку</Button> : null}
                </Box>

                {edit ?
                    <>
                        {
                            user.company_id === 12 ? (
                                <Box sx={{ mb: 2 }}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Виновник неисправности</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(e) => handlerCulprit(e.target.value)}
                                            defaultValue={checkCulprit()}
                                        >
                                            <FormControlLabel value={0} control={<Radio />} label="Нет" />
                                            {culprits.map(item => <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />)}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            ) : null
                        }
                        {
                            request.service.id === 12 ? (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.status_service_man === 1}
                                                onChange={(e) => checkWork(e, 'serviceman')} />} label="Отметка о готовности сотрудника сервиса" />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.status_engineer === 1}
                                                onChange={(e) => checkWork(e, 'engineer')} />} label="Отметка о готовности инженера" />
                                </FormGroup>
                            ) : (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.status_service_man === 1}
                                                onChange={(e) => checkWork(e, 'serviceman')} />} label="Отметка о готовности инженера сервиса" />
                                    <FormControlLabel
                                        disabled={user.company_id === 12 ? false : true}
                                        control={
                                            <Checkbox
                                                checked={data.status_engineer === 1}
                                                onChange={(e) => checkWork(e, 'engineer')} />} label="Отметка СДМ" />
                                </FormGroup>
                            )
                        }
                    </> : null}
            </Box>
        )
    }

    return (
        <>
            <Paper sx={{ mb: 4, p: 2 }}>
                <Typography variant="h6" component="h6" >
                    {cat}. {subCat}
                </Typography>
                <Box>
                    {data.comment.map(comment => <Comment key={comment.id} data={comment} />)}
                </Box>
                {request.status.id === 3 ? <EditBlock /> : null}
            </Paper>
            {open ? <AddWorkComment open={open} close={handleClose} workID={data.id} /> : null}
            {copy ? <CopyToRequest open={copy} close={handleCopyClose} work={data} /> : null}
        </>
    )
}

export default Work