import api from "../../services/api";
import { SET_NEW_COMPANY, SET_NEW_COMPANY_USERS, SET_NEW_MACHINE, SET_NEW_NOMER, SET_NEW_REQUEST, SET_SERVICE } from "./types";
import { toast } from "react-toastify";
import { getRequest } from '../Request/actions'

export const setNewNomer = data => {
    return {
        type: SET_NEW_NOMER,
        payload: data
    }
}

export const setNewMachine = data => {
    return {
        type: SET_NEW_MACHINE,
        payload: data
    }
}

export const getNewMachine = (nomer) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/machines/${nomer}/params`)

        if (response.status === 200) {
            const json = await response.data

            if (json.length !== 0) {
                dispatch(setNewMachine(json))
                if (json.owner) dispatch(getNewCompany(json.owner))
            } else {
                dispatch(setNewMachine(null))
                dispatch(setNewCompany(null))
                dispatch(setNewCompanyUsers([]))
            }
        }

    } catch (e) {
        dispatch(setNewMachine(null))
        dispatch(setNewCompany(null))
        dispatch(setNewCompanyUsers([]))
        console.error(e)
    }
}

export const setNewCompany = data => {
    return {
        type: SET_NEW_COMPANY,
        payload: data
    }
}

export const getNewCompany = (company) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/${company}`)

        if (response.status === 200) {
            const json = await response.data

            if (json.length !== 0) {
                dispatch(setNewCompany(json))
                dispatch(getNewCompanyUsers(company))
            } else {
                dispatch(setNewCompany(null))
                dispatch(setNewCompanyUsers([]))
            }
        }

    } catch (e) {
        dispatch(setNewCompany(null))
        dispatch(setNewCompanyUsers([]))
        console.error(e)
    }
}

export const setMachineService = data => {
    return {
        type: SET_SERVICE,
        payload: data
    }
}

export const getMachineService = (nomer) => async (dispatch, getStatus) => {
    try {
        const response = await api.get(`/api/v1/machines/${nomer}/service`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setMachineService(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const setNewCompanyUsers = data => {
    return {
        type: SET_NEW_COMPANY_USERS,
        payload: data
    }
}

export const getNewCompanyUsers = (company) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/${company}/users`)

        if (response.status === 200) {
            const json = await response.data

            if (json.length !== 0) {
                dispatch(setNewCompanyUsers(json))
            } else {
                dispatch(setNewCompanyUsers([]))
            }
        }

    } catch (e) {
        dispatch(setNewCompanyUsers([]))
        console.error(e)
    }
}

export const setNewRequestID = data => {
    return {
        type: SET_NEW_REQUEST,
        payload: data
    }
}

export const addRequest = (data) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/request`, data)

        if (response.status === 201) {
            const json = await response.data
            dispatch(setNewRequestID(json))
            dispatch(getRequest(json))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}