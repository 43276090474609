import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestEngineers, getRequestServiceMans } from "../../../store/Request/actions";
import * as RequestSelectors from "../../../store/Request/selectors";

const EditForm = ({ open, close, man, accept }) => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)
    const engineers = useSelector(RequestSelectors.engineers)
    const engineer = useSelector(RequestSelectors.engineer)
    const servicemans = useSelector(RequestSelectors.servicemans)
    const serviceman = useSelector(RequestSelectors.serviceman)

    const [list, setList] = useState([])
    const [item, setItem] = useState(0)

    useEffect(() => {
        dispatch(getRequestEngineers())
        dispatch(getRequestServiceMans())
    }, [])

    useEffect(() => {
        if (man === 'engineer') {
            setList(engineers)
            if (typeof request.workers !== "undefined") {
                const find = engineers.find(item => item.id === request.workers.engineer_id)
                if (find) {
                    setItem(find.id)
                }
            }
        } else {
            setList(servicemans)
            const find = servicemans.find(item => item.id === request.workers.service_man_id)
            if (find) {
                setItem(find.id)
            }
        }
    }, [engineers, servicemans])

    const handlerForm = (e) => {
        e.preventDefault()
        accept(item)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Изменить исполнителей"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            select
                            required
                            label="Сотрудник"
                            sx={{ width: '100%', mb: 2 }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={item}
                            onChange={e => setItem(parseInt(e.target.value))}
                        >
                            <option>Выбрать</option>
                            {list.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>

                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Сохранить</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default EditForm