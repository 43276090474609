import {Box, IconButton, List, ListItem, ListItemButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import FormTitle from "./FormTitle";
import {addParams, deleteParams, editParams, editParamsSort, getParams} from "../../store/Service/actions";
import FormEdit from "./FormEdit";

const ParamBlock = ({param, title, name, list}) => {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [editId, setEditId] = useState(null)
    const [oldTitle, setOldTitle] = useState("")

    useEffect(() => {
        dispatch(getParams(param))
    }, [param])

    const deleteItem = (id) => {
        dispatch(deleteParams(param, id))
    }
    
    const sort = (id, pos) => {
        const tmpList = []
        const tmpSort = list.find(item => item.id === id)['sort']

        list.forEach(item => {
            if (item.id === id) {
                item.sort = pos
            } else {
                if (pos > tmpSort) {
                    if (item.sort === pos) {
                        item.sort = pos - 1
                    }
                } else if (pos < tmpSort) {
                    if (item.sort === pos) {
                        item.sort = pos + 1
                    }
                }
            }

            tmpList.push({id: item.id, sort: item.sort})
        })

        dispatch(editParamsSort(param, tmpList))
    }

    const close = () => {
        setOpen(false)
    }

    const addItem = (value) => {
        const data = {
            title: value,
            sort: list.length + 1
        }
        dispatch(addParams(param, data))
        setOpen(false)
    }

    const editFun = (item) => {
        setEditId(item.id)
        setOldTitle(item.title)
        setEdit(true)
    }

    const closeEdit = () => {
        setEdit(false)
    }

    const editItem = (value) => {
        const data = {
            title: value
        }

        dispatch(editParams(param, editId, data))

        setEdit(false)
        setEditId(null)
        setOldTitle("")
    }

    const Item = ({item, index}) => {

        const lastSort = () => {
            return index === list.length - 1
        }

        return (
            <ListItem
                      disableGutters
                      secondaryAction={
                          <>
                              <IconButton aria-label="edit" onClick={() => editFun(item)}>
                                  <EditIcon fontSize={'small'} />
                              </IconButton>
                              <IconButton disabled={index === 0} aria-label="sort" onClick={() => sort(item.id, item.sort - 1)}>
                                  <ArrowUpwardIcon fontSize={'small'} />
                              </IconButton>
                              <IconButton disabled={lastSort()}  aria-label="sort" onClick={() => sort(item.id, item.sort + 1)}>
                                  <ArrowDownwardIcon fontSize={"small"} />
                              </IconButton>
                              <IconButton aria-label="delete" onClick={() => deleteItem(item.id)}>
                                  <DeleteOutlineIcon fontSize={'small'} />
                              </IconButton>
                          </>
                      }
            >
                <ListItemText primary={`${item.sort}. ${item.title}`} />
            </ListItem>
        )
    }

    return (
        <>
            <Box sx={{mb: 3}}>
                <Typography variant="h6" component="h6">
                    {title}
                </Typography>
                <List component="nav" aria-label="secondary mailbox folder">
                    {list.map((item, index) => <Item key={item.id} item={item} index={index} /> )}
                    <ListItemButton selected onClick={() => setOpen(true)}>
                        <ListItemText primary="Добавить" />
                    </ListItemButton>
                </List>
            </Box>
            {open ? <FormTitle open={open} close={close} name={name} value={e => addItem(e)} /> : null}
            {edit ? <FormEdit open={edit} close={closeEdit} oldValue={oldTitle} value={e => editItem(e)} /> : null}
        </>
    )
}

export default ParamBlock