import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Switch,
    TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as ServiceSelectors from "../../../store/Service/selectors";
import {getParams} from "../../../store/Service/actions";
import * as RequestSelectors from "../../../store/Request/selectors";
import { getRequestEngineers } from "../../../store/Request/actions";

const AcceptForm = ({open, close, accept}) => {

    const dispatch = useDispatch()

    const engineers = useSelector(RequestSelectors.engineers)
    const filters = useSelector(ServiceSelectors.filters)

    const [service, setExecutor] = useState(0)
    const [serviceE, setServiceE] = useState(false)

    const [filter, setFilter] = useState([])

    useEffect(() => {
        if (filters.length === 0) {
           dispatch(getParams('filters'))
        }
    }, [])

    useEffect(() => {
       dispatch(getRequestEngineers())
    }, [engineers.length])

    const handlerForm = (e) => {
        e.preventDefault()
        if (service === 0) {
            setServiceE(true)
        } else {
            accept({
                engineer_id: service,
                filter: filter.join()
            })
        }
    }

    const handlerFilter = (id) => {
        const index = filter.findIndex(item => item === id)

        if (index === -1) {
            const arr = Array.from(filter)
            arr.push(id)
            setFilter(arr)
        } else {
            const arr = Array.from(filter)
            arr.splice(index, 1)
            setFilter(arr)
        }
    }

    const Filter = ({item}) => {
        const [check, setCheck] = useState(false)

        useEffect(() => {
            setCheck(filter.findIndex(fil => fil === item.id) !== -1)
        }, [filter])

        return (
            <FormControlLabel
                control={
                    <Switch checked={check} onChange={() => handlerFilter(item.id)} name={item.title} />
                }
                label={item.title}
            />
        )
    }

    const Filters = () => {
        if (filters.length === 0) return null

        return (
            <>
                <FormControl component="fieldset" variant="standard" sx={{width: '100%', mb:2}}>
                    <FormLabel component="legend">Фильтры</FormLabel>
                    <FormGroup>
                        {filters.map(item => <Filter key={item.id} item={item} />)}
                    </FormGroup>
                </FormControl>
            </>
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Принять заявку"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            select
                            required
                            label="Инженер сервиса"
                            error={serviceE}
                            sx={{width: '100%', mb: 2}}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={service}
                            onChange={e => setExecutor(parseInt(e.target.value))}
                        >
                            <option>Выбрать</option>
                            {engineers.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                        <Filters />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Сохранить</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default AcceptForm