import * as RequestSelectors from "../../../store/Request/selectors";
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { getRequestWorks, takeRequest } from "../../../store/Request/actions";
import { getParams, getParamsCategories } from "../../../store/Service/actions"
import Work from "./Work";
import {
    Button, Box
} from "@mui/material";
import * as AuthSelector from "../../../store/Auth/selectors";
import AddWorkForm from "./AddWorkForm";

const RequestWorks = () => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)
    const works = useSelector(RequestSelectors.works)

    const roles = useSelector(AuthSelector.userRole)
    const user = useSelector(AuthSelector.user)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getRequestWorks(request.id))
        dispatch(getParamsCategories())
        dispatch(getParams('culprit'))
    }, [])

    const handleOpenClose = () => {
        setOpen(false)
    }

    const editAccess = () => {
        let access = false;

        const admin = roles.indexOf(6)
        const servise = roles.indexOf(5)

        if (request.workers) {
            if (request.workers.engineer_id === user.id) {
                access = true
            }
        }

        if (!access && admin === -1 && servise === -1) return false

        return true;
    }

    return (
        <>
            {editAccess() && request.status.id < 3 ?
                <Box sx={{ mb: 2 }}>
                    <Button variant="contained" onClick={() => dispatch(takeRequest(request.id))}>
                        Начать работу
                    </Button>
                </Box> : null}

            {works.map(work => <Work data={work} edit={editAccess()} key={work.id} />)}
            {editAccess() && request.status.id === 3 ?
                <Button onClick={() => setOpen(true)}>
                    Добавить задачу
                </Button> : null}

            {open ? <AddWorkForm open={open} close={handleOpenClose} requestID={request.id} /> : null}
        </>
    )
}

export default RequestWorks