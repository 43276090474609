import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl
} from "@mui/material";
import {useState} from "react";

const DenyForm = ({open, close, accept}) => {

    const [comment, setComment] = useState("")

    const handlerForm = (e) => {
        e.preventDefault()
        accept(comment)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Отклонить заявку?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField id="outlined-basic"
                                   onChange={event => setComment(event.target.value)}
                                   multiline={true}
                                   required
                                   sx={{width: '100%'}}
                                   label="Причина отклонения"
                                   value={comment}
                                   variant="outlined" />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Удалить</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default DenyForm