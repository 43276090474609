import { Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getRequests, setRequestsPage } from "../../store/Service/actions"
import * as ServiceSelectors from "../../store/Service/selectors"
import * as AuthSelectors from "../../store/Auth/selectors"
import moment from "moment/moment"


const RequestsList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const types = useSelector(ServiceSelectors.types)
    const user = useSelector(AuthSelectors.user)

    const status = useSelector(ServiceSelectors.status)
    const type = useSelector(ServiceSelectors.type)
    const filter = useSelector(ServiceSelectors.filter)
    const pages = useSelector(ServiceSelectors.pages)
    const page = useSelector(ServiceSelectors.page)

    const requests = useSelector(ServiceSelectors.requests)

    const toStr = (obj, query) => {
        const array = []

        Object.keys(obj).forEach(item => {
            if (obj[item]) {
                array.push(item)
            }
        })

        if (array.length > 0) {
            return (query + array.join())
        }

        return ""
    }

    useEffect(() => {
        if (status && type && filter) {
            let req = `?status=${status}${toStr(type, '&types=')}${toStr(filter, '&filters=')}&page=${page}`
            dispatch(getRequests(req))
        }
    }, [type, filter, status, page])

    const handlePage = (event, value) => {
        dispatch(setRequestsPage(value))
    };

    const workType = (id) => {
        const find = types.find(item => item.id === id)
        if (!find) return "Неизвестно"

        return find.title
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Заявка</TableCell>
                            <TableCell>Инженер</TableCell>
                            {
                                user.company_id === 12 ? <TableCell>Сервис</TableCell> : null
                            }
                            <TableCell>Тип работ</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Машина</TableCell>
                            <TableCell>Наработка</TableCell>
                            <TableCell>Дата заявки</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="td" scope="row">
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            navigate(`${row.id}`)
                                        }}
                                    >
                                        {row.id}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.engineer ? row.engineer.name : "Не назначен"}</TableCell>
                                {
                                    user.company_id === 12 ? <TableCell>{row.service ? row.service.title : "Нет данных"}</TableCell> : null
                                }
                                <TableCell>{workType(row.work_type_id)}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.nomer}</TableCell>
                                <TableCell>{row.work_time}</TableCell>
                                <TableCell>{moment(row.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination count={pages} page={page} onChange={handlePage} variant="outlined" shape="rounded" />
        </>
    )
}

export default RequestsList