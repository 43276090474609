import {
    SET_REQUESTS, SET_REQUESTS_CATEGORIES, SET_REQUESTS_CULPRITS, SET_REQUESTS_FILTER, SET_REQUESTS_FILTERS, SET_REQUESTS_PAGE, SET_REQUESTS_PAGES, SET_REQUESTS_STATUS, SET_REQUESTS_STATUSES, SET_REQUESTS_TYPE, SET_REQUESTS_TYPES
} from "./types";

const initialState = {
    requests: [],
    statuses: [],
    status: 1,
    filters: [],
    filter: null,
    types: [],
    type: null,
    page: 1,
    pages: 1,
    categories: [],
    culprits: []
}

export const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REQUESTS:
            return { ...state, requests: action.payload }
        case SET_REQUESTS_STATUSES:
            return { ...state, statuses: action.payload }
        case SET_REQUESTS_STATUS:
            return { ...state, status: action.payload }
        case SET_REQUESTS_FILTERS:
            return { ...state, filters: action.payload }
        case SET_REQUESTS_FILTER:
            return { ...state, filter: action.payload }
        case SET_REQUESTS_TYPES:
            return { ...state, types: action.payload }
        case SET_REQUESTS_TYPE:
            return { ...state, type: action.payload }
        case SET_REQUESTS_PAGES:
            return { ...state, pages: action.payload }
        case SET_REQUESTS_PAGE:
            return { ...state, page: action.payload }
        case SET_REQUESTS_CATEGORIES:
            return { ...state, categories: action.payload }
        case SET_REQUESTS_CULPRITS:
            return { ...state, culprits: action.payload }
        default:
            return state
    }
}