export const requests = state => state.ServiceReducer.requests
export const statuses = state => state.ServiceReducer.statuses
export const status = state => state.ServiceReducer.status
export const filters = state => state.ServiceReducer.filters
export const filter = state => state.ServiceReducer.filter
export const types = state => state.ServiceReducer.types
export const type = state => state.ServiceReducer.type
export const pages = state => state.ServiceReducer.pages
export const page = state => state.ServiceReducer.page
export const categories = state => state.ServiceReducer.categories
export const culprits = state => state.ServiceReducer.culprits