export const request = state => state.RequestReducer.request
export const machine = state => state.RequestReducer.machine
export const company = state => state.RequestReducer.company
export const user = state => state.RequestReducer.user
export const engineers = state => state.RequestReducer.engineers
export const engineer = state => state.RequestReducer.engineer
export const servicemans = state => state.RequestReducer.servicemans
export const serviceman = state => state.RequestReducer.serviceman
export const works = state => state.RequestReducer.works
export const report = state => state.RequestReducer.report
export const serviceComp = state => state.RequestReducer.serviceComp
