import {
    SET_REQUEST, SET_REQUEST_COMPANY, SET_REQUEST_ENGINEER, SET_REQUEST_ENGINEERS, SET_REQUEST_MACHINE, SET_REQUEST_REPORT, SET_REQUEST_SERVICEMAN, SET_REQUEST_SERVICEMANS, SET_REQUEST_USER, SET_REQUEST_WORKS, SET_SERVICE_COMPANY
} from "./types";

const initialState = {
    request: null,
    company: null,
    user: null,
    machine: null,
    engineers: [],
    engineer: null,
    servicemans: [],
    serviceman: null,
    works: [],
    report: null,
    serviceComp: []
}

export const RequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REQUEST:
            return { ...state, request: action.payload }
        case SET_REQUEST_MACHINE:
            return { ...state, machine: action.payload }
        case SET_REQUEST_COMPANY:
            return { ...state, company: action.payload }
        case SET_REQUEST_USER:
            return { ...state, user: action.payload }
        case SET_REQUEST_ENGINEERS:
            return { ...state, engineers: action.payload }
        case SET_REQUEST_ENGINEER:
            return { ...state, engineer: action.payload }
        case SET_REQUEST_SERVICEMANS:
            return { ...state, servicemans: action.payload }
        case SET_REQUEST_SERVICEMAN:
            return { ...state, serviceman: action.payload }
        case SET_REQUEST_WORKS:
            return { ...state, works: action.payload }
        case SET_REQUEST_REPORT:
            return { ...state, report: action.payload }
        case SET_SERVICE_COMPANY:
            return { ...state, serviceComp: action.payload }
        default:
            return state
    }
}