import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

const FormEdit = ({open, close, oldValue, value}) => {
    const [comment, setComment] = useState(oldValue)

    const handlerForm = (e) => {
        e.preventDefault()
        value(comment)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="dialog-title">
                        {`Изменить название`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>
                            ВАЖНО!!! Изменяйте только оргорафические ошибки! Если внести другое название пункта
                            все прикрепленные к ней задачи так же перейдут в другой раздел
                        </DialogContentText>
                        <TextField id="outlined-basic"
                                   onChange={event => setComment(event.target.value)}
                                   required
                                   sx={{width: '100%'}}
                                   label="Название"
                                   value={comment}
                                   variant="outlined" />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Сохранить</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default FormEdit