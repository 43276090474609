import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { ServiceReducer } from "./Service/reducers"
import { RequestReducer } from "./Request/reducers"
import { AddRequestReducer } from "./AddRequest/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    ServiceReducer,
    RequestReducer,
    AddRequestReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))