import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ServiceSelectors from "../../../store/Service/selectors";
import { getParams } from "../../../store/Service/actions";
import * as RequestSelectors from "../../../store/Request/selectors";
import { getServicesCompany, setDelegateService } from "../../../store/Request/actions";

const DelegateOtherCompanyForm = ({ id, open, close }) => {

    const dispatch = useDispatch()

    const serviceComp = useSelector(RequestSelectors.serviceComp)

    const [service, setExecutor] = useState(0)


    useEffect(() => {
        dispatch(getServicesCompany())
    }, [])

    const handlerForm = (e) => {
        e.preventDefault()
        dispatch(setDelegateService(id, service))
        close(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Изменить исполняющую компанию"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
                            Нажимая кнопку "сохранить" вы переназначите данную заявку на другой сервисный центр
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            select
                            required
                            label="Сервис"
                            sx={{ width: '100%', mb: 2 }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={service}
                            onChange={e => setExecutor(parseInt(e.target.value))}
                        >
                            <option>Выбрать</option>
                            {serviceComp.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Сохранить</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default DelegateOtherCompanyForm