import {Grid} from "@mui/material";
import ParamBlock from "./ParamBlock";
import ParamNestedList from "./ParamNestedList";
import {useSelector} from "react-redux";
import * as ServiceSelectors from "../../store/Service/selectors";

const Params = () => {

    const statuses = useSelector(ServiceSelectors.statuses)
    const types = useSelector(ServiceSelectors.types)
    const filters = useSelector(ServiceSelectors.filters)
    const culprits = useSelector(ServiceSelectors.culprits)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ParamNestedList />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <ParamBlock param={"status"} title={"Статусы задачи"} name={"статус"} list={statuses} />
                    <ParamBlock param={"types"} title={"Виды работ"} name={"вид работ"} list={types} />
                    <ParamBlock param={"filters"} title={"Фильтры"} name={"фильтр"} list={filters} />
                    <ParamBlock param={"culprit"} title={"Виновники"} name={"виновника"} list={culprits} />
                </Grid>
            </Grid>
        </>
    )
}

export default Params