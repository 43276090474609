import { useDispatch, useSelector } from "react-redux"
import * as RequestSelectors from "../../store/Request/selectors";
import * as ServiceSelectors from "../../store/Service/selectors";
import * as AuthSelector from "../../store/Auth/selectors";
import {
    Button,
    Grid,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    Box,
    Paper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import DescriptionIcon from '@mui/icons-material/Description';
import { getParams } from "../../store/Service/actions";
import { useEffect, useState } from "react";
import { delegateRequest, getRequestCompany, getRequestMachine, getRequestUser, rejectRequest, setRequestAccepted, setRequestUser } from "../../store/Request/actions";
import AcceptForm from './forms/AcceptForm'
import DenyForm from './forms/DenyForm'
import AddFiles from './forms/AddFiles'
import moment from "moment";
import DelegateOtherCompanyForm from "./forms/DelegateOtherCompanyForm";

const Filters = ({ filter }) => {

    const filters = useSelector(ServiceSelectors.filters)

    const setFilters = () => {
        let array = [];

        filter.forEach(filter => {
            const find = filters.find(f => f.id === filter.filter_id)
            if (find) array.push(find.title)
        });

        return array.join()
    }

    if (filters.length === 0) return null

    return (
        <>
            <TableRow>
                <TableCell>Наработка</TableCell>
                <TableCell align="right">{setFilters()}</TableCell>
            </TableRow>
        </>
    )
}

const RequestInfo = () => {

    const dispatch = useDispatch()

    const roles = useSelector(AuthSelector.userRole)

    const request = useSelector(RequestSelectors.request)
    const user = useSelector(RequestSelectors.user)
    const company = useSelector(RequestSelectors.company)
    const machine = useSelector(RequestSelectors.machine)
    const filters = useSelector(ServiceSelectors.filters)

    const authUser = useSelector(AuthSelector.user)

    const [openFiles, setOpenFiles] = useState(false)
    const [accept, setAccept] = useState(false)
    const [delegate, setDelegate] = useState(false)
    const [deny, setDeny] = useState(false)

    useEffect(() => {
        if (!company && request.company_id) dispatch(getRequestCompany(request.company_id))
    }, [company, dispatch])

    useEffect(() => {
        if (request.person_id && !user) dispatch(getRequestUser(request.person_id))

    }, [user])

    useEffect(() => {
        if (request.nomer && !machine) dispatch(getRequestMachine(request.nomer))
    }, [machine])

    useEffect(() => {
        if (filters.length === 0) {
            dispatch(getParams('filters'))
        }

        return () => {
            dispatch(setRequestUser(null))
        }

    }, [])

    const handleDenyClose = () => {
        setDeny(false);
    };

    const handleDelegateClose = () => {
        setDelegate(false)
    }

    const handleDenyAccept = (comment) => {
        setDeny(false);
        dispatch(rejectRequest(request.id, comment))
    };

    const handleAcceptClose = () => {
        setAccept(false);
    }

    const handleAccept = (e) => {
        setAccept(false)
        dispatch(delegateRequest(request.id, e.engineer_id, e.filter))
    }

    const handleFilesClose = () => {
        setOpenFiles(false)
    }

    const Info = () => {
        return (
            <>
                {request.status.id === 7 || request.status.id === 8 ?
                    <Box sx={{ mb: 2 }}>
                        <Paper sx={{ p: 2 }}>
                            <Typography>
                                Причина отмены/отклонения
                            </Typography>
                            {request.status.comment ?? "Не указана"}
                        </Paper>
                    </Box> : null}
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Номер машины</TableCell>
                                <TableCell align="right">{request.nomer}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Наработка</TableCell>
                                <TableCell align="right">{request.work_time} м/ч</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Тип работ</TableCell>
                                <TableCell align="right">{request.work_type.title}</TableCell>
                            </TableRow>
                            {request.filters.length > 0 ? <Filters filter={request.filters} /> : null}
                            <TableRow>
                                <TableCell>Описание</TableCell>
                                <TableCell align="right">{request.description}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Адрес</TableCell>
                                <TableCell align="right">{request.address}</TableCell>
                            </TableRow>
                            {request.lon && request.lat ?
                                <TableRow>
                                    <TableCell>Координаты машины</TableCell>
                                    <TableCell align="right">{request.lon} {request.lat}</TableCell>
                                </TableRow> : null}
                            <TableRow>
                                <TableCell>Дата заявки</TableCell>
                                <TableCell align="right">{moment(request.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const Files = () => {
        return (
            <>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {request.files.map(file => (
                        file.file_type === 'image' ?
                            <Box sx={{ width: 150, p: 1 }} key={file.id}>
                                <a target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                    <img style={{ width: '100%' }} src={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} />
                                </a>
                            </Box> : null
                    ))}
                </Box>

                <TableContainer sx={{ mb: 2 }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {request.files.map(file => (
                                file.file_type === 'doc' ?
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <a style={{ textDecoration: 'none' }} target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                                <Button variant="contained" startIcon={<DescriptionIcon />}>
                                                    {file.file}
                                                </Button>
                                            </a>
                                        </TableCell>
                                    </TableRow> : null
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button onClick={() => setOpenFiles(true)}>Добавить файлы</Button>
            </>
        )
    }

    const Machine = () => {
        if (!machine) return null

        return (
            <>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="td" scope="row">Номер</TableCell>
                                <TableCell align="right">{request.nomer}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td" scope="row">Машина</TableCell>
                                <TableCell align="right">{machine.type} {machine.model}.{machine.complectation}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td" scope="row">Заявка</TableCell>
                                <TableCell align="right">{machine.nomer_1c}</TableCell>
                            </TableRow>
                            {machine.warranty &&
                                <TableRow>
                                    <TableCell component="td" scope="row">Тип гарантии</TableCell>
                                    <TableCell align="right">{machine.warranty.hours} м/ч ({machine.warranty.month} мес.)</TableCell>
                                </TableRow>}
                            <TableRow>
                                <TableCell component="td" scope="row">Гарантия</TableCell>
                                <TableCell align="right">{machine.warranty_status === 1 ? "Да" : "Нет"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const Owner = () => {

        if (machine === null || typeof machine.owner_info === "undefined") return null

        return (
            <>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Компания</TableCell>
                                <TableCell align="right">{machine.owner_info.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">ИНН</TableCell>
                                <TableCell align="right">{machine.owner_info.inn}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Адрес</TableCell>
                                <TableCell align="right">{machine.owner_info.address}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const Contact = () => {

        return (
            <>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {request.person && request.person_phone ?
                                <TableRow>
                                    <TableCell component="th" scope="row">{request.person}</TableCell>
                                    <TableCell align="right">{request.person_phone}</TableCell>
                                </TableRow> : null}
                            {user ?
                                <TableRow>
                                    <TableCell component="th" scope="row">{user.name}</TableCell>
                                    <TableCell align="right">{user.phone}</TableCell>
                                </TableRow> : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const ActionButtons = () => {
        const admin = roles.indexOf(6)
        const serviceAdmin = roles.indexOf(5)

        if (admin === -1 && serviceAdmin === -1) return null

        return (
            <>
                {request.status.id === 1 && admin !== -1 && request.workers.engineer_id === null ?
                    <>
                        <Button onClick={() => setAccept(true)} sx={{ mr: 1 }}>{request.service.id === 12 ? "Принять в работу" : "Назначить инженера"}</Button>
                    </> : null}

                {request.status.id === 1 && serviceAdmin !== -1 ?
                    <>
                        <Button onClick={() => dispatch(setRequestAccepted(request.id))} sx={{ mr: 1 }}>Принять в работу</Button>
                    </> : null}

                {request.status.id === 1 && authUser.company_id === 12 ?
                    <>
                        <Button onClick={() => setDelegate(true)} sx={{ mr: 1 }}>Изменить компанию исполнителя</Button>
                    </> : null}

                {request.status.id !== 8 ?
                    <Button variant="outlined"
                        color="error"
                        onClick={() => setDeny(true)}
                    >Отклонить заявку</Button> : null}
            </>
        )
    }

    return (
        <>
            <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={6}>
                    <Info />
                </Grid>
                <Grid item xs={6}>
                    <Files />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h6">
                        Контакты
                    </Typography>
                    <Contact />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h6">
                        Информация о машине
                    </Typography>
                    <Machine />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h6">
                        Информация о владельце
                    </Typography>
                    <Owner />
                </Grid>
            </Grid>
            <ActionButtons />

            {delegate ? <DelegateOtherCompanyForm id={request.id} open={delegate} close={handleDelegateClose} /> : null}
            {accept ? <AcceptForm open={accept} close={handleAcceptClose} accept={(e) => handleAccept(e)} /> : null}
            {deny ? <DenyForm open={deny} close={handleDenyClose} accept={(e) => handleDenyAccept(e)} /> : null}
            {openFiles ? <AddFiles open={openFiles} close={handleFilesClose} requestID={request.id} /> : null}
        </>
    )
}

export default RequestInfo