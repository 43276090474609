import { FormControl, FormControlLabel, FormGroup, FormLabel, ListItemText, MenuItem, MenuList, Paper, Switch } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getParams, setRequestsFilter, setRequestsStatus, setRequestsType } from "../../store/Service/actions";
import * as ServiceSelectors from "../../store/Service/selectors";

const RequestsParams = () => {

    const dispatch = useDispatch()

    const statuses = useSelector(ServiceSelectors.statuses)
    const status = useSelector(ServiceSelectors.status)
    const types = useSelector(ServiceSelectors.types)
    const type = useSelector(ServiceSelectors.type)
    const filters = useSelector(ServiceSelectors.filters)
    const filter = useSelector(ServiceSelectors.filter)

    useEffect(() => {
        dispatch(getParams('status'))
        dispatch(getParams('types'))
        dispatch(getParams('filters'))
    }, [])

    useEffect(() => {
        if (type === null) {
            const tempType = {}

            if (types.length > 0) {
                types.forEach(element => {
                    tempType[element.id] = true
                });

                dispatch(setRequestsType(tempType))
            }
        }
    }, [types.length])

    useEffect(() => {
        if (filter === null) {
            const tempFilter = {}

            if (filters.length > 0) {
                filters.forEach(element => {
                    tempFilter[element.id] = false
                });

                dispatch(setRequestsFilter(tempFilter))
            }
        }
    }, [filters.length])

    const handleType = (event) => {
        dispatch(setRequestsType({
            ...type,
            [event.target.name]: event.target.checked,
        }))
    };

    const getTypeSwitch = (item) => {
        const switchItem = types.find(i => i.id === parseInt(item))
        return (
            <FormControlLabel
                key={item}
                control={
                    <Switch checked={type[parseInt(item)]} onChange={handleType} name={item} />
                }
                label={switchItem.title}
            />
        )
    }

    const handleFilter = (event) => {
        dispatch(setRequestsFilter({
            ...filter,
            [event.target.name]: event.target.checked,
        }))
    };

    const getFilterSwitch = (item) => {
        const switchItem = filters.find(i => i.id === parseInt(item))

        return (
            <FormControlLabel
                key={item}
                control={
                    <Switch checked={filter[parseInt(item)]} onChange={handleFilter} name={item} />
                }
                label={switchItem.title}
            />
        )
    }

    if (statuses.length === 0 || types.length === 0 || filters.length === 0) return null

    return (
        <>
            <Paper sx={{ width: 320, maxWidth: '100%', mb: 3 }}>
                <MenuList>
                    {statuses.map(item => (
                        <MenuItem key={item.id} selected={item.id === status} onClick={() => dispatch(setRequestsStatus(item.id))}>
                            <ListItemText>{item.title}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Paper>

            {type ?
                <FormControl component="fieldset" variant="standard" sx={{width: '100%', mb:2}}>
                    <FormLabel component="legend">Вид работ</FormLabel>
                    <FormGroup>
                        {Object.keys(type).map(item => getTypeSwitch(item))}
                    </FormGroup>
                </FormControl> : null 
            }

            {filter ?
                <FormControl component="fieldset" variant="standard" sx={{width: '100%', mb:2}}>
                    <FormLabel component="legend">Фильтры</FormLabel>
                    <FormGroup>
                        {Object.keys(filter).map(item => getFilterSwitch(item))}
                    </FormGroup>
                </FormControl> : null }
        </>
    )
}

export default RequestsParams