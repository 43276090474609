import { useDispatch, useSelector } from "react-redux";
import * as RequestSelectors from "../../../store/Request/selectors";
import {
    Box,
    Button,
    FormControlLabel, Grid,
    Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { addRequestReport, getRequestReport, successRequestReport } from "../../../store/Request/actions";
import AddFiles from "../forms/AddFiles";
import * as AuthSelector from "../../../store/Auth/selectors";

const RequestReport = () => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)
    const report = useSelector(RequestSelectors.report)

    const [comment, setComment] = useState("")
    const [commentInner, setCommentInner] = useState("")
    const [files, setFiles] = useState([])

    const roles = useSelector(AuthSelector.userRole)
    const user = useSelector(AuthSelector.user)

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        dispatch(getRequestReport(request.id))
    }, [])

    useEffect(() => {
        if (report) {
            setComment(report.public.comment)
            setCommentInner(report.inner.comment)
        }
    }, [report])

    const handlerSend = () => {
        const form = new FormData()

        form.append("comment", comment)
        form.append("inner_comment", commentInner)

        request.files.forEach((file, i) => {
            form.append(`file_update[${i}][id]`, file.id)
            form.append(`file_update[${i}][inner]`, files[i] ? 1 : 0)
        })

        dispatch(addRequestReport(request.id, form))
    }

    useEffect(() => {
        const tmp = []
        request.files.forEach(file => {
            tmp.push(file.inner === 1)
        })
        setFiles(tmp)
    }, [request])

    const handlerFile = (e, file, index) => {
        const tmp = Array.from(files)
        tmp[index] = e
        setFiles(tmp)
    }

    const adminAccess = () => {
        const admin = roles.indexOf(6)
        if (admin === -1) return false
        return true;
    }

    const editAccess = () => {
        let access = false;

        const admin = roles.indexOf(6)

        if (request.workers) {
            if (request.workers.engineer_id === user.id) {
                access = true
            }
        }

        if (!access && admin === -1) return false

        return true;
    }

    const Files = () => {
        return (
            <>
                <TableContainer sx={{ mb: 2 }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {request.files.map((file, i) => (
                                <TableRow key={file.id}>
                                    <TableCell>
                                        {file.file_type === 'doc' ?
                                            <Button variant="contained" startIcon={<DescriptionIcon />}>
                                                {file.file}
                                            </Button> : <img style={{ width: 100 }} src={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} />}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            user.company_id === 12 ? (
                                                <FormControlLabel control={<Switch checked={files[i]} onChange={e => handlerFile(e.target.checked, file.id, i)} />} label="Виден всем" />
                                            ) : null
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const FilesView = ({ view }) => {
        return (
            <>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {request.files.map((file, i) => (
                        file.file_type === 'image' && files[i] === view ?
                            <Box sx={{ width: 150, p: 1 }} key={file.id}>
                                <a target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                    <img style={{ width: '100%' }} src={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} />
                                </a>
                            </Box> : null
                    ))}
                </Box>

                <TableContainer sx={{ mb: 2 }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {request.files.map((file, i) => (
                                file.file_type === 'doc' && files[i] === view ?
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <a style={{ textDecoration: 'none' }} target="_blank" href={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} download>
                                                <Button variant="contained" startIcon={<DescriptionIcon />}>
                                                    {file.file}
                                                </Button>
                                            </a>
                                        </TableCell>
                                    </TableRow> : null
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const EditReport = () => {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                                Отчет
                            </Typography>
                            <TextField id="outlined-basic"
                                onChange={event => setComment(event.target.value)}
                                multiline={true}
                                required
                                sx={{ width: '100%', mb: 2 }}
                                rows={5}
                                label="Общий отчет"
                                value={comment}
                                variant="outlined" />
                            {
                                user.company_id === 12 ? (
                                    <TextField id="outlined-basic"
                                        onChange={event => setCommentInner(event.target.value)}
                                        multiline={true}
                                        required
                                        sx={{ width: '100%', mb: 2 }}
                                        rows={5}
                                        label="Комментарии для сервиса"
                                        value={commentInner}
                                        variant="outlined" />
                                ) : null
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h6" component="h6">
                                Файлы
                            </Typography>
                            <Files />
                            <Button onClick={() => setOpen(true)}>Добавить файлы</Button>
                        </Box>
                    </Grid>
                </Grid>
                {comment !== "" && commentInner !== "" ?
                    <Box>
                        <Button type="submit" onClick={() => handlerSend()}>Сохранить</Button>
                    </Box> : null}
                {report && adminAccess() ?
                    <Box>
                        <Button variant="contained" type="submit" onClick={() => dispatch(successRequestReport(request.id))}>Отчет принят</Button>
                    </Box> : null}
                {open ? <AddFiles open={open} close={handleClose} requestID={request.id} /> : null}
            </>
        )
    }

    const ViewReport = () => {

        if (!report) {
            return (
                <Box>Нет отчета</Box>
            )
        }
        return (
            <>
                <Box>
                    <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                        Общий отчет
                    </Typography>
                    <Box sx={{ mb: 3 }}>{comment}</Box>
                    <FilesView view={true} />

                    <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                        Внутренний отчет
                    </Typography>
                    <Box>{commentInner}</Box>
                    <FilesView view={false} />
                </Box>
            </>
        )
    }

    if (request.status.id > 4) return <ViewReport />

    if (editAccess()) {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                                Отчет
                            </Typography>
                            <TextField id="outlined-basic"
                                onChange={event => setComment(event.target.value)}
                                multiline={true}
                                required
                                sx={{ width: '100%', mb: 2 }}
                                rows={5}
                                label="Общий отчет"
                                value={comment}
                                variant="outlined" />

                            {
                                user.company_id === 12 ? (
                                    <TextField id="outlined-basic"
                                        onChange={event => setCommentInner(event.target.value)}
                                        multiline={true}
                                        required
                                        sx={{ width: '100%', mb: 2 }}
                                        rows={5}
                                        label="Комментарии для сервиса"
                                        value={commentInner}
                                        variant="outlined" />
                                ) : null
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h6" component="h6">
                                Файлы
                            </Typography>
                            <Files />
                            <Button onClick={() => setOpen(true)}>Добавить файлы</Button>
                        </Box>
                    </Grid>
                </Grid>
                {comment !== "" ?
                    <Box>
                        <Button type="submit" onClick={() => handlerSend()}>Сохранить</Button>
                    </Box> : null}
                {report && adminAccess() ?
                    <Box>
                        <Button variant="contained" type="submit" onClick={() => dispatch(successRequestReport(request.id))}>Отчет принят</Button>
                    </Box> : null}
                {open ? <AddFiles open={open} close={handleClose} requestID={request.id} /> : null}
            </>
        )
    }

    return <ViewReport />
}

export default RequestReport