import * as React from 'react';
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Chip, Link, Box, Tabs, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { getRequest } from "../../store/Request/actions";
import * as RequestSelectors from "../../store/Request/selectors";
import * as AuthSelectors from "../../store/Auth/selectors"
import RequestInfo from "./RequestInfo";
import PropTypes from 'prop-types';
import RequestExecutors from './RequestExecutors';
import RequestWorks from './RequestWorks';
import RequestReport from './RequestReport';

const Request = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const request = useSelector(RequestSelectors.request)
    const user = useSelector(AuthSelectors.user)

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getRequest(id))
    }, [])

    const getChip = () => {
        let color;

        switch (request.status.id) {
            case 1:
                color = "warning"
                break
            case 2:
                color = "info"
                break
            case 3:
                color = "info"
                break
            case 4:
                color = "info"
                break
            case 5:
                color = "success"
                break
            case 8:
                color = "error"
                break
            case 7:
                color = "error"
                break
            default:
                color = "info"
                break
        }

        return (
            <Chip label={request.status.title} color={color} sx={{ ml: 2 }} />
        )
    }

    const TopRequest = () => {
        return (
            <>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        navigate("/service")
                    }}
                >
                    Назад
                </Link>
                <Typography variant="h3" component="h2" sx={{ mb: 2 }}>
                    Заявка №{request.id}
                    {getChip()}
                </Typography>
            </>
        )
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    if (!request) return null;

    return (
        <>
            <TopRequest />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Информация о заявке" {...a11yProps(0)} />
                        <Tab label="Исполнители" {...a11yProps(1)} disabled={user.company_id === 12 ? false : true} />
                        <Tab label="Работы" {...a11yProps(2)} />
                        {request.status.id > 2 ? <Tab label="Отчёт" {...a11yProps(3)} /> : null}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <RequestInfo />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <RequestExecutors />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <RequestWorks />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <RequestReport />
                </TabPanel>
            </Box>
        </>
    )
}

export default Request