import {
    SET_AUTH,
    SET_AUTH_EMAIL,
    SET_AUTH_PASSWORD,
    SET_EXPRES_IN,
    SET_TOKEN,
    SET_USER, SET_USER_ROLE
} from "./types";

const initialState = {
    auth: null,
    email: "",
    password: "",
    token: localStorage.getItem("token"),
    expires_in: localStorage.getItem("expires_in"),
    user: [],
    userRole: localStorage.getItem("roles") ? JSON.parse(localStorage.getItem("roles")) : []
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_EMAIL:
            return { ...state, email: action.payload }
        case SET_AUTH_PASSWORD:
            return { ...state, password: action.payload }
        case SET_TOKEN:
            return { ...state, token: action.payload }
        case SET_EXPRES_IN:
            return { ...state, expires_in: action.payload }
        case SET_AUTH:
            return { ...state, auth: action.payload }
        case SET_USER:
            return { ...state, user: action.payload }
        case SET_USER_ROLE:
            return { ...state, userRole: action.payload }
        default:
            return state
    }
}