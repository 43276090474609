import {useDispatch, useSelector} from "react-redux";
import * as RequestSelectors from "../../../store/Request/selectors";
import {useState} from "react";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,TextField
} from "@mui/material";
import {addRequestWorkComment} from '../../../store/Request/actions'
import FilesUpload from "../../../components/FilesUpload";

const AddWorkComment = ({open, close, workID}) => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)

    const [comment, setComment] = useState("")
    const [files, setFiles] = useState([])

    const handlerForm = (e) => {
        e.preventDefault()
        close()
        dispatch(addRequestWorkComment(request.id, workID, comment, files))
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Добавить комментарий"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField id="outlined-basic-c"
                                   onChange={event => setComment(event.target.value)}
                                   required
                                   multiline
                                   sx={{width: '100%', mb: 2}}
                                   label="Комментарий"
                                   value={comment}
                                   variant="outlined" />
                        <FilesUpload handleFilesArray={e => {setFiles(e)}} />
                        <DialogActions>
                            <Button type="submit">Добавить</Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

export default AddWorkComment