import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import * as RequestSelectors from "../../store/Request/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { delegateRequest, getRequestEngineer, getRequestServiceMan, setRequestEngineer, setRequestServiceMan, setServiceManRequest } from "../../store/Request/actions";
import EditForm from "./forms/EditForm";
import * as AuthSelector from "../../store/Auth/selectors";

const RequestExecutors = () => {

    const dispatch = useDispatch()

    const roles = useSelector(AuthSelector.userRole)
    const user = useSelector(AuthSelector.user)

    const request = useSelector(RequestSelectors.request)

    const engineer = useSelector(RequestSelectors.engineer)
    const serviceman = useSelector(RequestSelectors.serviceman)

    const [edit, setEdit] = useState(false)
    const [editMan, setEditMan] = useState(null)

    useEffect(() => {
        if (request.workers) {
            if (request.workers.engineer_id) dispatch(getRequestEngineer(request.workers.engineer_id))
            if (request.workers.service_man_id) dispatch(getRequestServiceMan(request.workers.service_man_id))
        }

        return () => {
            dispatch(setRequestEngineer(null))
            dispatch(setRequestServiceMan(null))
        }
    }, [])

    const handleSetEdit = (e) => {
        setEdit(true)
        setEditMan(e)
    }

    const handleEditClose = () => {
        setEdit(false);
    }

    const handleEdit = (e) => {
        if (editMan === 'engineer') {
            dispatch(delegateRequest(request.id, e, ""))
        } else {
            dispatch(setServiceManRequest(request.id, e))
        }
    }

    const EditEngineer = () => {
        const auth = roles.indexOf(6)

        if (auth === -1) return null

        return (
            <>
                <IconButton aria-label="edit" onClick={() => handleSetEdit('engineer')}>
                    <EditIcon fontSize={'small'} />
                </IconButton>
            </>
        )
    }

    const EditServiceMan = () => {
        let access = false;

        const admin = roles.indexOf(6)

        if (request.workers) {
            if (request.workers.engineer_id === user.id && roles.indexOf(5) === -1) {
                access = true
            }
        }

        if (!access && admin === -1) return null

        return (
            <IconButton aria-label="edit" onClick={() => handleSetEdit('serviceman')}>
                <EditIcon fontSize={'small'} />
            </IconButton>
        )
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="td" scope="row">Инженер</TableCell>
                            <TableCell align="right">{engineer ? engineer.name : "Не назначен"}</TableCell>
                            <TableCell align="right">
                                <EditEngineer />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="td" scope="row">Сотрудник сервиса</TableCell>
                            <TableCell align="right">{serviceman ? serviceman.name : "Не назначен"}</TableCell>
                            <TableCell align="right">
                                <EditServiceMan />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {edit ? <EditForm open={edit} close={handleEditClose} man={editMan} accept={(e) => handleEdit(e)} /> : null}
        </>
    )
}

export default RequestExecutors