export const SET_REQUESTS = "SET_REQUESTS"
export const SET_REQUESTS_STATUSES = "SET_REQUESTS_STATUSES"
export const SET_REQUESTS_STATUS = "SET_REQUESTS_STATUS"
export const SET_REQUESTS_FILTERS = "SET_REQUESTS_FILTERS"
export const SET_REQUESTS_FILTER = "SET_REQUESTS_FILTER"
export const SET_REQUESTS_TYPES = "SET_REQUESTS_TYPES"
export const SET_REQUESTS_TYPE = "SET_REQUESTS_TYPE"
export const SET_REQUESTS_PAGES = "SET_REQUESTS_PAGES"
export const SET_REQUESTS_PAGE = "SET_REQUESTS_PAGE"
export const SET_REQUESTS_CATEGORIES = "SET_REQUESTS_CATEGORIES"
export const SET_REQUESTS_CULPRITS = "SET_REQUESTS_CULPRITS"