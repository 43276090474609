export const SET_REQUEST = "SET_REQUEST"
export const SET_REQUEST_MACHINE = "SET_REQUEST_MACHINE"
export const SET_REQUEST_COMPANY = "SET_REQUEST_COMPANY"
export const SET_REQUEST_USER = "SET_REQUEST_USER"
export const SET_REQUEST_ENGINEERS = "SET_REQUEST_ENGINEERS"
export const SET_REQUEST_SERVICEMANS = "SET_REQUEST_SERVICEMANS"
export const SET_REQUEST_ENGINEER = "SET_REQUEST_ENGINEER"
export const SET_REQUEST_SERVICEMAN = "SET_REQUEST_SERVICEMAN"
export const SET_REQUEST_WORKS = "SET_REQUEST_WORKS"
export const SET_REQUEST_REPORT = "SET_REQUEST_REPORT"
export const SET_SERVICE_COMPANY = "SET_SERVICE_COMPANY"