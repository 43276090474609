import {useDispatch, useSelector} from "react-redux";
import * as ServiceSelectors from "../../../store/Service/selectors";
import {useEffect, useState} from "react";
import {addRequestWork} from "../../../store/Request/actions";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,TextField
} from "@mui/material";
import FilesUpload from "../../../components/FilesUpload";

const AddWorkForm = ({open, close, requestID}) => {

    const dispatch = useDispatch()
    const categories = useSelector(ServiceSelectors.categories)

    const [cat, setCat] = useState(0)
    const [subCat, setSubCat] = useState(0)
    const [comment, setComment] = useState("")
    const [files, setFiles] = useState([])

    const handlerForm = (e) => {
        e.preventDefault()
        dispatch(addRequestWork(requestID, subCat, comment, files))
        close()
    }

    const SubCat = () => {

        const [array, setArray] = useState([])

        useEffect(() => {
            if (cat !== 0) {
                const index = categories.findIndex(item => item.id === cat)
                setArray(categories[index].children)
            } else {
                setSubCat(0)
            }
        }, [cat])

        if (cat === 0) return null

        return (
            <>
                <TextField
                    margin="dense"
                    select
                    required
                    label="Подраздел"
                    sx={{width: '100%', mb: 2}}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    value={subCat}
                    onChange={e => setSubCat(parseInt(e.target.value))}
                >
                    <option>Выбрать</option>
                    {array.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.title}
                        </option>
                    ))}
                </TextField>
            </>
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Добавить задачу"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{mb: 2}}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            select
                            required
                            label="Категория"
                            sx={{width: '100%', mb: 2}}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={cat}
                            onChange={e => setCat(parseInt(e.target.value))}
                        >
                            <option value={0}>Выбрать</option>
                            {categories.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <SubCat />
                        <TextField id="outlined-basic-c"
                                   onChange={event => setComment(event.target.value)}
                                   required
                                   multiline
                                   sx={{width: '100%', mb: 2}}
                                   label="Комментарий"
                                   value={comment}
                                   variant="outlined" />
                        <FilesUpload handleFilesArray={e => {setFiles(e)}} />
                        <DialogActions>
                            <Button type="submit">Сохранить</Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

export default AddWorkForm