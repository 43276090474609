import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as AddRequestSelectors from "../../store/AddRequest/selectors";
import {
    Box,
    Button,
    Grid,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { addRequest, getMachineService, getNewMachine, setNewCompany, setNewCompanyUsers, setNewMachine, setNewNomer, setNewRequestID } from "../../store/AddRequest/actions";
import * as ServiceSelectors from "../../store/Service/selectors";
import { getParams } from "../../store/Service/actions";
import FilesUpload from "../../components/FilesUpload";

const AddRequest = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const types = useSelector(ServiceSelectors.types)

    const nomer = useSelector(AddRequestSelectors.nomer)
    const machine = useSelector(AddRequestSelectors.machine)
    const company = useSelector(AddRequestSelectors.company)
    const companyUsers = useSelector(AddRequestSelectors.companyUsers)
    const service = useSelector(AddRequestSelectors.service)

    const newRequest = useSelector(AddRequestSelectors.newRequest)

    const [workTime, setWorkTime] = useState(0)
    const [RType, setRType] = useState(0)
    const [RTypeE, setRTypeE] = useState(false)
    const [ROrg, setROrg] = useState(0)
    const [ROrgE, setROrgE] = useState(false)
    const [comment, setComment] = useState("")
    const [address, setAddress] = useState("")
    const [companyForm, setCompanyForm] = useState("")
    const [companyINN, setCompanyINN] = useState("")
    const [companyPerson, setCompanyPerson] = useState(0)
    const [person, setPerson] = useState("")
    const [phone, setPhone] = useState("")
    const [files, setFiles] = useState([])

    const reset = () => {
        dispatch(setNewNomer(null))
        dispatch(setNewMachine(null))
        dispatch(setNewRequestID(null))
        dispatch(setNewCompany(null))
        dispatch(setNewCompanyUsers([]))
    }

    useEffect(() => {
        if (newRequest !== null) {
            navigate(`/service/${newRequest}`)
            reset()
        }
    }, [newRequest])

    const handlerForm = (e) => {
        e.preventDefault()

        setROrgE(ROrg === 0)
        setRTypeE(RType === 0)
        if (RType !== 0) {
            const data = new FormData()
            data.append("nomer", nomer)
            data.append('service_id', ROrg)
            data.append("work_time", workTime)
            data.append("description", comment)
            data.append("work_type_id", RType)
            data.append("address", address)
            data.append("inn", companyINN)

            if (files.length > 0) {
                files.forEach((item, i) => {
                    data.append(`files[${i}]`, item, item.name)
                })
            }

            if (company !== null) {
                data.append("company_id", company.id)
            } else {
                data.append("company", companyForm)
            }

            if (companyPerson !== 0) {
                data.append("person_id", companyPerson)
            }

            if (person !== "") data.append("person", person)
            if (phone !== "") data.append("person_phone", phone)

            dispatch(addRequest(data))
        }
    }

    useEffect(() => {
        dispatch(getParams('types'))
    }, [])

    const handlerNomer = () => {
        setCompanyForm("")
        setCompanyINN("")
        setAddress("")
        dispatch(getMachineService(nomer))
        dispatch(getNewMachine(nomer))
    }

    useEffect(() => {
        if (company) {
            setCompanyForm(company.title)
            setCompanyINN(company.inn)
            setAddress(company.address)
        }
    }, [company])

    const Machine = () => {
        return (
            <Box sx={{ mb: 2 }}>
                <Paper>
                    <Typography sx={{ p: 1 }}>
                        {machine.type} {machine.model}
                    </Typography>
                </Paper>
            </Box>
        )
    }

    const CompanyUsers = () => {
        if (companyUsers.lendth === 0) return null
        return (
            <TextField
                margin="dense"
                select
                label="Контактное лицо"
                sx={{ width: '100%', mb: 2, mt: 0 }}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
                value={companyPerson}
                onChange={e => setCompanyPerson(parseInt(e.target.value))}
            >
                <option>Выбрать</option>
                {companyUsers.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name} — {option.phone}
                    </option>
                ))}
            </TextField>
        )
    }

    return (
        <>
            <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
                Добавить заявку
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box>
                        <form onSubmit={event => handlerForm(event)} autoComplete="off">
                            <Box sx={{ mb: 2 }}>
                                <TextField id="outlined-basic"
                                    onChange={event => dispatch(setNewNomer(event.target.value))}
                                    onBlur={handlerNomer}
                                    required
                                    sx={{ width: '30%', mr: 2 }}
                                    label="Номер машины"
                                    value={nomer}
                                    variant="outlined" />
                                <TextField id="outlined-basic"
                                    onChange={event => setWorkTime(event.target.value)}
                                    required
                                    type={"number"}
                                    sx={{ width: '30%' }}
                                    label="Наработка машины"
                                    value={workTime}
                                    variant="outlined" />
                            </Box>
                            {machine ? <Machine /> : null}
                            <TextField id="outlined-basic"
                                onChange={event => setCompanyForm(event.target.value)}
                                required
                                sx={{ width: '100%', mb: 2 }}
                                label="Компания"
                                value={companyForm}
                                variant="outlined" />
                            <TextField id="outlined-basic"
                                onChange={event => setCompanyINN(event.target.value)}
                                required
                                sx={{ width: '100%', mb: 2 }}
                                label="ИНН"
                                value={companyINN}
                                variant="outlined" />
                            <TextField id="outlined-basic"
                                onChange={event => setAddress(event.target.value)}
                                required
                                sx={{ width: '100%', mb: 2 }}
                                label="Адрес"
                                value={address}
                                variant="outlined" />
                            <CompanyUsers />
                            <TextField id="outlined-basic"
                                onChange={event => setPerson(event.target.value)}
                                sx={{ width: '100%', mb: 2 }}
                                label="Контактное лицо"
                                value={person}
                                variant="outlined" />
                            <TextField id="outlined-basic"
                                onChange={event => setPhone(event.target.value)}
                                sx={{ width: '100%', mb: 2 }}
                                label="Телефон"
                                value={phone}
                                variant="outlined" />
                            <TextField
                                margin="dense"
                                select
                                required
                                label="Тип работ"
                                error={RTypeE}
                                sx={{ width: '100%', mb: 2 }}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                value={RType}
                                onChange={e => setRType(parseInt(e.target.value))}
                            >
                                <option>Выбрать</option>
                                {types.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.title}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                margin="dense"
                                select
                                required
                                label="Исполняющая органгизация"
                                error={ROrgE}
                                sx={{ width: '100%', mb: 2 }}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                value={ROrg}
                                onChange={e => setROrg(parseInt(e.target.value))}
                            >
                                <option>Выбрать</option>
                                {service.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.title}
                                    </option>
                                ))}
                            </TextField>
                            <TextField id="outlined-basic"
                                onChange={event => setComment(event.target.value)}
                                required
                                multiline
                                sx={{ width: '100%', mb: 2 }}
                                label="Комментарий"
                                value={comment}
                                variant="outlined" />
                            <FilesUpload handleFilesArray={e => { setFiles(e) }} />
                            <Button type="submit">Сохранить заявку</Button>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default AddRequest