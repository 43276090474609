import api from "../../services/api";
import { SET_REQUESTS, SET_REQUESTS_CATEGORIES, SET_REQUESTS_CULPRITS, SET_REQUESTS_FILTER, SET_REQUESTS_FILTERS, SET_REQUESTS_PAGE, SET_REQUESTS_STATUS, SET_REQUESTS_STATUSES, SET_REQUESTS_TYPE, SET_REQUESTS_TYPES } from "./types";
import {toast} from "react-toastify";

export const setRequestsStatus = data => {
    return {
        type: SET_REQUESTS_STATUS,
        payload: data
    }
}

export const setRequestsType = data => {
    return {
        type: SET_REQUESTS_TYPE,
        payload: data
    }
}

export const setRequestsFilter = data => {
    return {
        type: SET_REQUESTS_FILTER,
        payload: data
    }
}

export const setRequestsPage = data => {
    return {
        type: SET_REQUESTS_PAGE,
        payload: data
    }
}

export const setRequests = data => {
    return {
        type: SET_REQUESTS,
        payload: data
    }
}

export const getRequests = (req) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/work/request${req}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRequests(json.data))
        }
    } catch (e) {
        console.error(e)
    }
}

// PARAMS

export const setParamsStatuses = data => {

    data.sort((a, b) => parseInt(a.sort) - parseInt(b.sort))

    return {
        type: SET_REQUESTS_STATUSES,
        payload: data
    }
}

export const setParamsTypes = data => {

    data.sort((a, b) => parseInt(a.sort) - parseInt(b.sort))

    return {
        type: SET_REQUESTS_TYPES,
        payload: data
    }
}

export const setParamsFilters = data => {

    data.sort((a, b) => parseInt(a.sort) - parseInt(b.sort))

    return {
        type: SET_REQUESTS_FILTERS,
        payload: data
    }
}

export const setParamsCulprits = data => {

    data.sort((a, b) => parseInt(a.sort) - parseInt(b.sort))

    return {
        type: SET_REQUESTS_CULPRITS,
        payload: data
    }
}

export const getParams = (param) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/work/${param}`)

        if (response.status === 200) {
            const json = await response.data
            switch (param) {
                case 'status':
                    dispatch(setParamsStatuses(json))
                    break
                case 'types':
                    dispatch(setParamsTypes(json))
                    break
                case 'filters':
                    dispatch(setParamsFilters(json))
                    break
                case 'culprit':
                    dispatch(setParamsCulprits(json))
                    break
                default:
                    break
            }
        }
    } catch (e) {
        console.error(e)
    }
}

export const setParamsCategories = data => {

    return {
        type: SET_REQUESTS_CATEGORIES,
        payload: data
    }
}

export const getParamsCategories = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/work/category')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setParamsCategories(json))
        }
    } catch (e) {
        console.error(e)
    }
}


export const deleteParams = (param, id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/work/${param}/${id}`)

        if (response.status === 200) {
            dispatch(getParams(param))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const editParamsSort = (param, data) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/work/${param}/sort`, data)

        if (response.status === 200) {
            dispatch(getParams(param))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const addParams = (param, data) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/work/${param}`, data)

        if (response.status === 201) {
            dispatch(getParams(param))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const editParams = (param, id, data) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/work/${param}/${id}`, data)

        if (response.status === 200) {
            dispatch(getParams(param))
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}


export const addParamsCategories = (data) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/work/category', data)

        if (response.status === 201) {
            dispatch(getParamsCategories())
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const editParamsCategories = (id, data) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/work/category/${id}`, data)

        if (response.status === 200) {
            dispatch(getParamsCategories())
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}

export const deleteParamsCategories = (id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/work/category/${id}`)

        if (response.status === 200) {
            dispatch(getParamsCategories())
            toast.success('Успешно')
        }
    } catch (e) {
        console.error(e)
        toast.error('Ошибка')
    }
}