import { SET_NEW_COMPANY, SET_NEW_COMPANY_USERS, SET_NEW_MACHINE, SET_NEW_NOMER, SET_NEW_REQUEST, SET_SERVICE } from "./types";

const initialState = {
    nomer: "",
    machine: null,
    company: null,
    companyUsers: [],
    newRequest: null,
    service: []
}

export const AddRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_NOMER:
            return { ...state, nomer: action.payload }
        case SET_NEW_MACHINE:
            return { ...state, machine: action.payload }
        case SET_NEW_COMPANY:
            return { ...state, company: action.payload }
        case SET_NEW_COMPANY_USERS:
            return { ...state, companyUsers: action.payload }
        case SET_NEW_REQUEST:
            return { ...state, newRequest: action.payload }
        case SET_SERVICE:
            return { ...state, service: action.payload }
        default:
            return state
    }
}