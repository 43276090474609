import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react"
import CloseIcon from '@mui/icons-material/Close';

const FilesUpload = ({handleFilesArray}) => {

    const [files, setFiles] = useState([]);

    const handleAddImg = (imgs) => {
        setFiles([
            ...files,
            ...imgs
        ])
    }

    const handleDeleteImg = (index) => {
        const file = files.filter((item, i) => i !== index)
        setFiles(file)
    }

    useEffect(() => {
        handleFilesArray(files)
    }, [files])

    return (
        <>
            <Box sx={{mb: 2}}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '0 -5px',
                    'div': {
                        width: '77px',
                        height: '77px',
                        mb: '10px',
                        mr: '5px',
                        ml: '5px',
                        position: "relative",
                        'img': {
                            width: '100%',
                            height: '100%',
                            borderRadius: "4px",
                            zIndex: "1",
                            backgroundColor: '#c4c4c4',
                            objectFit: "cover"
                        },
                        '.delete-img': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            borderRadius: "4px",
                            zIndex: '-1',
                            opacity: 0,
                            cursor: 'pointer',
                            transition: '0.3s'
                        },
                        ':hover .delete-img': {
                            zIndex: 2,
                            opacity: 1,
                            transition: '0.5s'
                        }
                    }
                }}>
                    { files.map((item, i) => (
                            <div key={i}>
                                <img src={(item.type === 'image/jpeg' || item.type === 'image/png') ? URL.createObjectURL(item) : process.env.PUBLIC_URL + '/docs.png'} alt={item.name} />
                                <button onClick={() => handleDeleteImg(i)} className='delete-img'>
                                    <CloseIcon sx={{ color: "#3498db" }} />
                                </button>
                            </div>
                        ))
                    }
                </Box>
                <Box>
                    <Button variant="contained" component="label">
                        Добавить файлы
                        <input hidden multiple type="file" onChange={e => handleAddImg(e.target.files)} />
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default FilesUpload