import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow,
    TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ServiceSelectors from "../../../store/Service/selectors";
import DescriptionIcon from "@mui/icons-material/Description";
import { getParams } from "../../../store/Service/actions";
import { copyRequestWork } from '../../../store/Request/actions'
import * as RequestSelectors from "../../../store/Request/selectors";

const CopyToRequest = ({ open, close, work }) => {

    const dispatch = useDispatch()

    const request = useSelector(RequestSelectors.request)
    const types = useSelector(ServiceSelectors.types)

    const [comment, setComment] = useState("")
    const [RType, setRType] = useState(0)
    const [RTypeE, setRTypeE] = useState(false)
    const [files, setFiles] = useState([])

    useEffect(() => {
        if (types.length === 0) dispatch(getParams('types'))
    }, [])

    const handlerForm = (e) => {
        e.preventDefault()
        setRTypeE(RType === 0)

        if (RType !== 0) console.log(request.id, work.id, comment, files, RType)

        close()
        dispatch(copyRequestWork(request.id, work.id, comment, files, RType))
    }

    const addFile = (fileID) => {
        const array = Array.from(files)
        const index = array.indexOf(fileID)
        if (index > -1) {
            array.splice(index, 1)
        } else {
            array.push(fileID)
        }
        setFiles(array)
    }

    const Image = ({ file }) => {
        const index = files.indexOf(file.id)
        return (
            <Box sx={{ width: 50, p: 1, opacity: index > -1 ? 1 : .5 }} key={file.id} onClick={() => addFile(file.id)}>
                <img style={{ width: '100%' }} src={`https://service.dstapi.ru/uploads/tmp/${request.id}/${file.file}`} />
            </Box>
        )
    }

    const Doc = ({ file }) => {
        const index = files.indexOf(file.id)
        return (
            <Button variant={index > -1 ? "contained" : "outlined"} startIcon={<DescriptionIcon />} onClick={() => addFile(file.id)}>
                {file.file}
            </Button>
        )
    }

    const Files = () => {
        return (
            <>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {work.files.map(file => (
                        file.file_type === 'image' ? <Image key={file.id} file={file} /> : null
                    ))}
                </Box>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            {work.files.map(file => (
                                file.file_type === 'doc' ?
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <Doc file={file} />
                                        </TableCell>
                                    </TableRow> : null
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Создать заявку"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                        <TextField id="outlined-basic-c"
                            onChange={event => setComment(event.target.value)}
                            required
                            multiline
                            sx={{ width: '100%', mb: 2 }}
                            label="Комментарий"
                            value={comment}
                            variant="outlined" />
                        <TextField
                            margin="dense"
                            select
                            required
                            label="Тип работ"
                            error={RTypeE}
                            sx={{ width: '100%', mb: 2 }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={RType}
                            onChange={e => setRType(parseInt(e.target.value))}
                        >
                            <option>Выбрать</option>
                            {types.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <Box sx={{ mb: 2 }}>
                            <Files />
                        </Box>
                        <DialogActions>
                            <Button type="submit">Создать</Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

export default CopyToRequest