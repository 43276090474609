import {useDispatch} from "react-redux";
import {useState} from "react";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle
} from "@mui/material";
import { addRequestFiles } from "../../../store/Request/actions";
import FilesUpload from "../../../components/FilesUpload";

const AddFiles = ({open, close, requestID}) => {

    const dispatch = useDispatch()

    const [files, setFiles] = useState([])

    const handlerForm = (e) => {
        e.preventDefault()
        dispatch(addRequestFiles(requestID, files))
        close()
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={event => handlerForm(event)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">
                        {"Добавить файлы"}
                    </DialogTitle>
                    <DialogContent>
                        <FilesUpload handleFilesArray={e => {setFiles(e)}} />
                        <DialogActions>
                            <Button type="submit">Сохранить</Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

export default AddFiles