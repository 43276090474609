import {Collapse, List, ListItemButton, Box, ListItem, IconButton} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ListItemText from "@mui/material/ListItemText";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import * as ServiceSelectors from "../../store/Service/selectors";
import {
    addParamsCategories,
    deleteParamsCategories,
    editParamsCategories,
    getParamsCategories
} from "../../store/Service/actions";
import FormTitle from "./FormTitle";
import FormEdit from "./FormEdit";

const ParamNestedList = () => {

    const dispatch = useDispatch()

    const categories = useSelector(ServiceSelectors.categories)

    const [open, setOpen] = useState(false)
    const [cats, setCats] = useState([])
    const [parent, setParent] = useState(null)
    const [name, setName] = useState("")

    const [edit, setEdit] = useState(false)
    const [editId, setEditId] = useState(null)
    const [oldTitle, setOldTitle] = useState("")

    useEffect(() => {
        dispatch(getParamsCategories())
    }, [])

    useEffect(() => {
        if (cats.length === 0) {
            const tmp = []
            categories.forEach(item => {
                tmp.push(false)
            })
            setCats(tmp)
        }
    }, [categories])

    const deleteItem = (e, id) => {
        e.stopPropagation()
        dispatch(deleteParamsCategories(id))
    }

    const editFun = (e, id, title) => {
        e.stopPropagation()
        setOldTitle(title)
        setEditId(id)
        setEdit(true)
    }

    const editItem = (e) => {
        const data = {
            title: e
        }
        dispatch(editParamsCategories(editId, data))
        setEditId(null)
        setOldTitle("")
        setEdit(false)
    }

    const closeEdit = () => {
        setEdit(false)
    }

    const close = () => {
        setOpen(false)
    }

    const addFun = (value) => {
        if (value === null) {
            setName("раздел")
            setParent(null)
        } else {
            setName(` в «${value.title}»`)
            setParent(value.id)
        }

        setOpen(true)
    }

    const addItem = (value) => {
        const data = {
            title: value
        }

        if (parent) data.parent_id = parent

        dispatch(addParamsCategories(data))
        setParent(null)
        setOpen(false)
    }

    const Item = ({data}) => {
        return (
            <>
                <ListItem
                    disableGutters
                    divider
                    secondaryAction={
                    <>
                        <IconButton aria-label="comment" onClick={(e) => editFun(e, data.id, data.title)}>
                            <EditIcon fontSize={'small'} />
                        </IconButton>
                        <IconButton aria-label="comment" onClick={(e) => deleteItem(e, data.id)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </>
                    }
                >
                    <ListItemText primary={`${data.title}`} />
                </ListItem>
            </>
        )
    }

    const Block = ({data, index}) => {

        const handleClick = () => {
            const tmp = Array.from(cats)

            tmp[index] = !tmp[index]

            setCats(tmp)
        };

        return (
            <>
                <ListItemButton onClick={handleClick} divider>
                    <ListItemText primary={data.title} />

                    <IconButton aria-label="comment" onClick={(e) => editFun(e, data.id, data.title)}>
                        <EditIcon fontSize={'small'} />
                    </IconButton>

                    {!data.children || data.children.length === 0 ?
                    <IconButton aria-label="comment" onClick={(e) => deleteItem(e, data.id)}>
                        <DeleteOutlineIcon fontSize={'small'} />
                    </IconButton> : null }

                    {cats[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={cats[index]} timeout="auto" unmountOnExit>
                    <List component="div" sx={{pl: 4}} disablePadding>
                        {data.children && data.children.map(item => <Item key={item.id} data={item} />)}
                        <ListItemButton selected onClick={() => addFun(data)}>
                            <ListItemText secondary={`Добавить пункт в «${data.title}»`} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </>
        )
    }

    return (
        <>
            <Box sx={{mb: 3}}>
                <Typography variant="h6" component="h6">
                    Категории
                </Typography>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {categories.map((item, i) => <Block key={item.id} data={item} index={i} />)}
                    <ListItemButton selected onClick={() => addFun(null)}>
                        <ListItemText secondary="Добавить раздел" />
                    </ListItemButton>
                </List>
            </Box>
            {open ? <FormTitle open={open} close={close} name={name} value={e => addItem(e)} /> : null}
            {edit ? <FormEdit open={edit} close={closeEdit} oldValue={oldTitle} value={e => editItem(e)} /> : null}
        </>
    )
}

export default ParamNestedList